#content-core,
#tinymce{
  p{
    @include clearfix;
  }

  img{
    &.image-left{
      float:left;
      margin-right:1rem;
    }
    &.image-right{
      float:right;
      margin-left:1rem;
    }
  }
  p.collapes-head{
    margin-bottom:2px;    
  }
  
  .collapse{
    margin-bottom:2px;
  }

  section{
    &#section-text{
      margin-bottom:1rem
    }
  }

  div{
    &.w-33{
      width:33%;
    }
    &.w-66{
      width:66%;
    }
  }

  figure {
    &.image-inline{
      display: inline-table;
    }    
  }

}

// keep in sync with picture variant image definitions 
#tinymce{
  img{
    
    &.picture-variant-logo{
      max-width:128px;
    }
    
    &.picture-variant-small{
      max-width:200px;
    }

    &.picture-variant-medium{
      max-width:400px;
    }

    &.picture-variant-large{
      max-width:1000px;
    }
  }
}

ul{

  &.linklist{
    margin-left:0;
    padding-left:0;
    list-style-type: none;
    >li{
      border-bottom:1px solid $gray-800;
      padding-top:0.5rem;
      padding-bottom:0.5rem;
      padding-left:1rem;
      padding-right:1rem;
      
      &:first-child{
        border-top:3px solid $gray-800;
      }
      
      background-color: var(--nct-navigation-portlet-bg-color);
      
      &:hover{
        background-color: rgb(var(--bs-primary-rgb));
        color:white;
        a{
          color:white;
        }
      }
      >a{
        display:block;        
        text-decoration:none;
        &::after{
          content:"\00BB";
          padding-left:1rem;
        }
      }
    }
  }
}

div{
  &.bg-light {
    --bs-bg-opacity: 1;
    background-color: rgba(var(--nct-navigation-portlet-bg-color-rgb), var(--bs-bg-opacity));
    transition: background-color 300ms ease-in-out;
    &:hover {      
      background-color: rgba($gray-300, var(--bs-bg-opacity));
      transition: background-color 300ms ease-in-out;
    }
  }

  &.invertbox{
    background-color:rgba(var(--bs-primary-rgb));
    h1,h2,h3,p,a{
      color:white;
    }
  }
}
