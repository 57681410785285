.portletNavigationTree{
  ul{
    list-style: none;
    margin:0;padding:0;

    >.navTreeItem{
      background-color: var(--nct-navigation-portlet-bg-color);
      border-bottom:2px solid white;

      &:last-child{
        border-bottom:0;
      }

      > a{
        color:var(--bs-body-color);
        text-decoration: none;
        display:block;
        padding-right: calc(var(--bs-gutter-x) * .5);
        padding-left: calc(var(--bs-gutter-x) * .5);
        padding-top: calc(var(--bs-gutter-x) * .5);
        padding-bottom: calc(var(--bs-gutter-x) * .5);
        transition: background-color 300ms ease-in-out, color 300ms ease-in-out;
        &:hover{
          background-color: rgb(var(--bs-primary-rgb));
          transition: background-color 300ms ease-in-out, color 300ms ease-in-out;
          color:white;
        }
      }

      
    }

    &.navTreeLevel0{
      >.navTreeItem{
        &.navTreeItemInPath,
        &.navTreeCurrentNode{
          > a{
            background-color: rgb(var(--bs-primary-rgb));
            color:white;
          }
        }
      }
    }

    &.navTreeLevel1,
    &.navTreeLevel2{
      >.navTreeItem{
        >a{
          border-left:3px solid;
          border-color: rgba(var(--bs-primary-rgb), 1);
        }
        &.navTreeItemInPath,
        &.navTreeCurrentNode{
          >a{
            border-color: rgb(var(--bs-primary-rgb));
            background-color:rgba(var(--bs-primary-rgb));
            color:white;
            &:hover{
              color:rgba(var(--bs-primary-rgb));
              background-color:white;
            }
          }
        }
      }
    }

    &.navTreeLevel1{
      >.navTreeItem{
        > a{
          padding-left: calc(var(--bs-gutter-x) * 1);
        }
      }
    }
    
    &.navTreeLevel2{
      >.navTreeItem{
        > a{
          padding-left: calc(var(--bs-gutter-x) * 1.5);
        }
      }
    }

  }
}