#portal-languageselector{
  list-style:none;
  display:flex;
  margin:0;
  padding:0;
  align-items: center;
  margin-right:1rem;
  
  li{
    display: flex;
    align-items: stretch;
    &:first-child{
      padding-right:1rem;
      border-right: 1px solid var(--bs-body-color);
    }
    &:last-child{
      padding-left:1rem
    }
  }
}
#viewlet-emergency{
  .btn{
    border-radius: 0;
    color:red;
  }
  span{
    color:black;
    position: relative;
    top:1px;
  }
}

#portal-logo-wrapper img{
  min-height:100px;
}
#portal-logo-ukd{
  display: var(--nct-ukd-logo-visible)
}
#portal-anontools{
  margin-right:1rem;
  ul{
    margin-bottom:0;
  }
}
#portal-breadcrumbs{
  position: relative;
  z-index: 2;
  background-color: rgba(255,255,255,0.85);
  padding: 0.5rem;
}

#section-nct-sectionleadimage{
  position:relative;
  top:-2.5rem
}

body.col-one.col-content{
  #portal-column-content{
    width:100%;
    @include media-breakpoint-up(lg) { width:70%; }
    margin-bottom:1rem;
  }
  #portal-column-one{
    margin-bottom:1rem;
  }
}

#content{
  header h1{
    margin-bottom:1.5rem;
  }
}


#footer-logos{
  background-color:rgb(var(--nct-color-darkblue-rgb));  
  color:rgba(255,255,255, 0.5);
  .footer-logo{
    max-height:30px;
    filter: opacity(80%);
    transition: filter 0.3s ease;   
    &:hover{
      filter: opacity(100%);
    }
  }
}

#footer-actions{
  
  background-color:var(--bs-body-color);  
  color:rgba(white, 0.5);
  padding-bottom:5rem;
  
  .dot{
    height: 2rem;
    width: 2rem;
    line-height:1rem;
    display: inline-block;    
    border-radius: 50%;
    
    color:var(--bs-body-color);
    background-color: rgba(255,255,255, 0.3);
    
    &:hover{
      color:var(--bs-link-hover-color-rgb);
      background-color: rgba(255,255,255, 0.1);
    }
  }

  #portal-siteactions{
    margin:0;padding:0;list-style:none;
    li{
      display:block;     
      @include media-breakpoint-up(md) {
        display:inline-block;
      }
      margin-right:1rem;
      &:last-child{
        margin-right:0;
      }
    }
    a{
      color:rgba(var(--nct-footer-link-color-rgb), 0.5);
      transition: color 0.2s ease;
    }
    a:hover{
      color:rgba(var(--nct-footer-link-color-rgb), 0.8);
    }
  }

  .footer-line{
    height:0.3rem;
    background:rgba(var(--nct-footer-link-color-rgb), 0.5);
    border:0;
    opacity: 0.5;
    margin:0;
  }
}

.livesearch-results{
  position: absolute;
  z-index: 3;
  top:3rem;  
}

#section-byline{
  margin-bottom:2rem;
}

/*Override Buttons for Collapse*/
.collapes-head{
  button{
    &.btn-primary{
      svg{
        color:rgb(var(--bs-primary-rgb));
        transform: rotate(0deg);
        transition: transform 0.2s;
      }
      &[aria-expanded="true"]{
        svg{
          transform: rotate(180deg);
        }
      }
    }
  }  
}

.collapse,
.collapsing{
  padding:1rem;
}


.accordion{
  .accodion-title-wrapper{
    flex-grow: 1;
    margin-right: 1rem;
    hr{
      border-style: dashed;
      border-top-width:2px;
    }
  }
  .accordion-item{
    margin-bottom:0;
    &:last-child{
      margin-bottom:1rem;
    }
  }
  .accordion-item:not(:first-of-type){
    border-top: var(--bs-accordion-border-width) solid var(--bs-accordion-border-color);
  }
}


/*Override alert box*/
.alert{
  border:0;
  .h1, .h2, .h3, .h4, .h5, .h6, h1, h2, h3, h4, h5, h6 {
    color: #fff;
  }
}

/*Override Carousel for Homepage*/
#carousel-homepage{
  
  .carousel-item{
    img{
      width:100% !important;
    }
  }

  .carousel-caption{
    bottom:auto;
    top:0rem;
    left:0%;
    right:auto;
    max-width:100%;
    
    @include media-breakpoint-up(lg) {
      bottom:auto;
      top:6rem;
      left:15%;
      right:auto;
      max-width:25%;
    }
    text-align: left;
    padding:0;
    

    h2{
      background-color:rgba(var(--nct-color-blue-rgb), .9);
      transition: background-color 0.5s ease;
      color:white;
      padding:1rem;
      margin:0;
      font-weight:300;
    }
    p{
      margin:0;
    }
    a{
      background-color: rgba(var(--nct-color-green-rgb), .9);
      transition: background-color 0.5s ease;
      color:white;
      padding:1rem;
      text-decoration: none;
    }

    &:hover{
      h2{
        background-color:rgba(var(--nct-color-blue-rgb), 1);
      }
      a{
        background-color: rgba(var(--nct-color-green-rgb), 1);
        text-decoration: underline;
      }
    }
  }

  .carousel-indicators{
    margin-bottom:0;
    margin-right:0;
    margin-left:0;
    background-color: rgba(var(--nct-color-green-rgb), .5);
    [data-bs-target] {
      width:auto;
      height:auto;
      text-indent: 0;
      &:hover{
        opacity: 1;
      }
    } 
  }  
}


#fullsize-panel{
  position: relative;
  width:100%;
  
  #carousel-homepage{
    position: relative;
  }

  #newsbox-homepage{
    @include media-breakpoint-up(lg) {
      position: absolute;
      z-index: 2;
      right:15%;
      left:auto;
      top:6rem;
      max-width: 25%;
    }
  }
}

// News Box Startseite
#newsbox-homepage{
  min-width:20%;
  h2{
    background-color: rgba(var(--nct-color-darkblue-rgb), .9);
    color:white;
    margin-bottom:3px;
    font-weight:300;
    padding:1rem;
  }
  ul{
    list-style: none;
    margin:0;
    padding:0;
    li{
      background-color: rgba(var(--nct-color-darkblue-rgb), .9);
      padding:1rem;
      margin:0;

      a{
        color:#d9d9d9;     
        display:block;
        transition: color 0.3s ease;   
      }
      
      &:hover{
        
        a{
          color: #ffffff;       
        }
      }      
      
    }
  }
    
}
// Section Tile view
body.col-one.col-content.template-section-tile-view #portal-column-content{
  width:100%;
}

#section-navi-content{
  padding: 1rem;
  height:100%;
  background:rgba(var(--bs-primary-rgb), .9);
  width:100%;
  
  @include media-breakpoint-up(lg) {
    width:50%;
  }

  h1, p{
    color:white;
  }
  
  #section-navi{
    list-style:none;
    margin:0;
    padding:0;
    columns:2;

    @include media-breakpoint-up(lg) {
      margin-bottom:0;
      columns:2;
    }

    li{
      padding:0.5rem;
      border-top:1px solid white;
      background-color:rgba(255,255,255,0);
      transition: background-color 0.3s ease;

      &:hover{       
        background-color:rgba(255,255,255,.2)
      }
      
      &:last-child{
        border-bottom:1px solid white;
      }
    }
    a{
      display:block;
      color:white;
      text-decoration:none;
    }
  }
}

.section-tile{
  width:100%;
  margin-bottom:1rem;
  @include media-breakpoint-up(lg) {
    width:50%;
    margin-bottom:0;
  }
  
  > div{
    width:50%;
  }

  &:nth-child(3),
  &:nth-child(4),
  &:nth-child(7),
  &:nth-child(8)
  &:nth-child(11),
  &:nth-child(12){
    flex-direction:row-reverse;
  }
  background-color: rgba(242, 242, 242, 1);
  transition: background-color 0.3s ease;
  h3,p {
    color:#666666;
    word-wrap: break-word
  }
  a{
    color:#666666
  }
  img{
    filter: saturate(100%);
    transition: filter 0.3s ease;
  }
  &:hover{
    img{
      filter: saturate(50%);
    }
    background-color: rgb(204, 204, 204);
  }
}

body{
  
  /*Template summary_view*/
  &.template-summary_view{
    #content-core article{
      h2{
        font-size:1.75rem;
        a{
          text-decoration:none;
        }
      }
    }
  }
  
  /*Template event_view*/
  &.template-event_view{
    .meta-icon{
      display: flex;
        align-content: flex-start;
        flex-direction: row;
        align-items: flex-start;
        padding-right:1rem;
    }
  }
  
  /*Template event_view*/
  &.template-event_listing{
    .cal_date{
      min-width:15%
    }
    .tileHeadline{
      font-size:1.75rem;
      a{
        text-decoration:none;
      }
    }
  }
}

/*figcaption*/
figcaption{
  &.image-caption{
    font-size:85%;
    font-style: italic;
  }
}
