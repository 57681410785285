.navbar-nct {
  #portal-globalnav {
    --bs-navbar-background: white;

    > li {
      &.inPath {
        > a {
          background: none;
        }
        &:hover {
          > a,
          > label:after {
            color: white;
          }
        }
      }

      &:hover {
        color: white;

        a,
        label:after {
          color: white;
        }

        > a,
        > label:after {
          color: white;
        }

        a:hover {
          color: white;
        }

        ul li,
        ul li a {
          color: white;
        }

        ul li label:after {
          color: white;
        }
      }

      &:nth-of-type(1) {
        > a,
        > label:after {
          color: rgb(var(--nct-color-green-rgb));
        }

        &.inPath {
          > a {
            border-bottom: 5px solid rgb(var(--nct-color-green-rgb));

            &:hover {
              background: rgb(var(--nct-color-green-rgb));
            }
          }
          &:hover {
            background: rgb(var(--nct-color-green-rgb));
          }
        }

        &:hover {
          background: rgb(var(--nct-color-green-rgb));

          > a,
          > label:after {
            color: white;
          }

          ul li,
          ul li a {
            background: rgb(var(--nct-color-green-rgb));
          }

          > a:hover {
            background: rgb(var(--nct-color-green-rgb));
          }
        }
      }

      &:nth-of-type(2) {
        > a,
        > label:after {
          color: rgb(var(--nct-color-blue-rgb));
        }

        &.inPath {
          > a {
            border-bottom: 5px solid rgb(var(--nct-color-blue-rgb));

            &:hover {
              background: rgb(var(--nct-color-blue-rgb));
            }
          }
          &:hover {
            background: rgb(var(--nct-color-blue-rgb));
          }
        }

        &:hover {
          background: rgb(var(--nct-color-blue-rgb));

          > a,
          > label:after {
            color: white;
          }

          ul li,
          ul li a {
            background: rgb(var(--nct-color-blue-rgb));
          }

          > a:hover {
            background: rgb(var(--nct-color-blue-rgb));
          }
        }
      }

      &:nth-of-type(3) {
        > a,
        > label:after {
          color: rgb(var(--nct-color-darkblue-rgb));
        }

        &.inPath {
          > a {
            border-bottom: 5px solid rgb(var(--nct-color-darkblue-rgb));

            &:hover {
              background: rgb(var(--nct-color-darkblue-rgb));
            }
          }
          &:hover {
            background: rgb(var(--nct-color-darkblue-rgb));
          }
        }

        &:hover {
          background: rgb(var(--nct-color-darkblue-rgb));

          > a,
          > label:after {
            color: white;
          }

          ul li,
          ul li a {
            background: rgb(var(--nct-color-darkblue-rgb));
          }

          > a:hover {
            background: rgb(var(--nct-color-darkblue-rgb));
          }
        }
      }

      &:nth-of-type(4) {
        > a,
        > label:after {
          color: rgb(var(--nct-color-gray-rgb));
        }

        &.inPath {
          > a {
            border-bottom: 5px solid rgb(var(--nct-color-gray-rgb));

            &:hover {
              background: rgb(var(--nct-color-gray-rgb));
            }
          }
          &:hover {
            background: rgb(var(--nct-color-gray-rgb));
          }
        }

        &:hover {
          background: rgb(var(--nct-color-gray-rgb));

          > a,
          > label:after {
            color: white;
          }

          ul li,
          ul li a {
            background: rgb(var(--nct-color-gray-rgb));
          }

          > a:hover {
            background: rgb(var(--nct-color-gray-rgb));
          }
        }
      }
    }
  }
}
