:root{


  $default-text-color: #4c4c4c;
  $default-text-color-rgb: 76,76,76;

  $default-green-color: #a0b400;
  $default-green-color-rgb: 160,180,0;

  $default-blue-color: #3273c3;
  $default-blue-color-rgb: 50,115,195;

  $default-darkblue-color: #00186e;
  $default-darkblue-color-rgb: 0,24,110;

  $default-gray-color: #818181;
  $default-gray-color-rgb: 129,129,129;

  // colors

  --nct-color-green:          #{$default-green-color};
  --nct-color-blue:           #{$default-blue-color};
  --nct-color-darkblue:       #{$default-darkblue-color};
  --nct-color-gray:           #{$default-gray-color};

  --nct-color-green-rgb:      #{$default-green-color-rgb};
  --nct-color-blue-rgb:       #{$default-blue-color-rgb};
  --nct-color-darkblue-rgb:   #{$default-darkblue-color-rgb};
  --nct-color-gray-rgb:       #{$default-gray-color-rgb};

  // override text colors
  --bs-body-color:            #{$default-text-color};
  --bs-link-hover-color:      #{$default-text-color};
  --bs-link-hover-color-rgb:  #{$default-text-color-rgb};

  // override buttons
  --bs-btn-border-radius: 0;
  --bs-border-radius: 0;

  // overrride breadcrumb
  .breadcrumb {
    --bs-breadcrumb-margin-bottom:0;
  }

  // custom css vars
  --nct-ukd-logo-visible: none;
  --nct-navigation-portlet-bg-color: #ededed;
  --nct-navigation-portlet-bg-color-rgb: 237,237,237;

  --nct-footer-link-color:#ffffff;
  --nct-footer-link-hover-color:#ffffff;

  --nct-footer-link-color-rgb: 255,255,255;
  --nct-footer-link-hover-color-rgb: 255,255,255;

  body{

    --bs-primary-rgb:  var(--nct-color-gray-rgb);
    --bs-primary:  var(--nct-color-gray);
    --bs-link-color-rgb: var(--nct-color-gray-rgb);
    --bs-heading-color: var(--nct-color-gray);

    // the primary button
    --nct-btn-color: #fff;
    --nct-btn-bg: var(--bs-heading-color);
    --nct-btn-border-color: var(--bs-heading-color);
    --nct-btn-hover-color: #fff;
    --nct-btn-hover-bg: var(--bs-heading-color);
    --nct-btn-hover-border-color: var(--bs-heading-color);
    --nct-btn-focus-shadow-rgb: 38,143,189;
    --nct-btn-active-color: #fff;
    --nct-btn-active-bg: var(--bs-heading-color);
    --nct-btn-active-border-color: var(--bs-heading-color);
    --nct-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
    --nct-btn-disabled-color: #fff;
    --nct-btn-disabled-bg: var(--bs-heading-color);
    --nct-btn-disabled-border-color: var(--bs-heading-color);
    --nct-btn-border-radius:0;


    // override alert colors
    .alert-dark {
      --bs-alert-color:white;
    }

    &.section-fuer-patienten,
    &.section-for-patients{
      --bs-primary-rgb: var(--nct-color-green-rgb);
      --bs-primary: var(--nct-color-green);
      --bs-link-color-rgb: var(--nct-color-green-rgb);
      --bs-heading-color: var(--nct-color-green);
      --nct-ukd-logo-visible: block;


      --nct-btn-color: var(--bs-body-color);
      --nct-btn-bg:#f5f5f5;
      --nct-btn-border-color:#f5f5f5;

      --nct-btn-hover-color:var(--bs-body-color);
      --nct-btn-hover-bg: #f8faed;
      --nct-btn-hover-border-color: #f8faed;

      --nct-btn-active-bg: #f8faed;
      --nct-btn-active-border-color: #f8faed;

      --nct-btn-disabled-bg: #f5f5f5;
      --nct-btn-disabled-border-color: #f5f5f5;

      // override alert colors
      .alert-dark {
        --bs-alert-bg: rgb(var(--nct-color-green-rgb));
      }

      // override accordion
      .accordion {
        --bs-accordion-bg: #f5f5f5;
        --bs-accordion-border-color: white;
        --bs-accordion-active-bg: #f8faed;
        --bs-accordion-btn-focus-border-color:#f8faed;
        --bs-accordion-btn-focus-box-shadow: 0 0 0 0.25rem rgba(var(--nct-color-green-rgb), 0.25);
      }

      //override collapse
      .collapse,
      .collapsing{
        background-color:#f8faed;
      }

    }
    &.section-fuer-aerzte,
    &.section-for-physicians{
      --bs-primary-rgb: var(--nct-color-blue-rgb);
      --bs-primary: var(--nct-color-blue);
      --bs-link-color-rgb: var(--nct-color-blue-rgb);
      --bs-heading-color: var(--nct-color-blue);
      --nct-ukd-logo-visible: block;


      --nct-btn-color: var(--bs-body-color);
      --nct-btn-bg:#f5f5f5;
      --nct-btn-border-color:#f5f5f5;

      --nct-btn-hover-color:var(--bs-body-color);
      --nct-btn-hover-bg: #f1f5f5;
      --nct-btn-hover-border-color: #f1f5f5;

      --nct-btn-active-bg: #f1f5f5;
      --nct-btn-active-border-color: #f1f5f5;

      --nct-btn-disabled-bg: #f5f5f5;
      --nct-btn-disabled-border-color: #f5f5f5;

      // override alert colors
      .alert-dark {
        --bs-alert-bg: rgb(var(--nct-color-blue-rgb));
      }


      // override accordion
      .accordion {
        --bs-accordion-bg: #f1f5f5;
        --bs-accordion-border-color: white;
        --bs-accordion-active-bg: #f1f5f5;
        --bs-accordion-btn-focus-border-color:#f1f5f5;
        --bs-accordion-btn-focus-box-shadow: 0 0 0 0.25rem rgba(var(--nct-color-blue-rgb), 0.25);
      }

      //override collapse
      .collapse,
      .collapsing{
        background-color:#f1f5f5;
      }

    }
    &.section-forschung,
    &.section-research{
      --bs-primary-rgb:  var(--nct-color-darkblue-rgb);
      --bs-primary:  var(--nct-color-darkblue);
      --bs-link-color-rgb: var(--nct-color-darkblue-rgb);
      --bs-heading-color: var(--nct-color-darkblue);


      --nct-btn-color: var(--bs-body-color);
      --nct-btn-bg:#f5f5f5;
      --nct-btn-border-color:#f5f5f5;

      --nct-btn-hover-color:var(--bs-body-color);
      --nct-btn-hover-bg: #edeff5;
      --nct-btn-hover-border-color: #edeff5;

      --nct-btn-active-bg: #edeff5;
      --nct-btn-active-border-color: #edeff5;

      --nct-btn-disabled-bg: #f5f5f5;
      --nct-btn-disabled-border-color: #f5f5f5;

      // override alert colors
      .alert-dark {
        --bs-alert-bg: rgb(var(--nct-color-darkblue-rgb));
      }

      // override accordion
      .accordion {
        --bs-accordion-bg: #edeff5;
        --bs-accordion-border-color: white;
        --bs-accordion-active-bg: #edeff5;
        --bs-accordion-btn-focus-border-color:#edeff5;
        --bs-accordion-btn-focus-box-shadow: 0 0 0 0.25rem rgba(var(--nct-color-blue-rgb), 0.25);
      }

      //override collapse
      .collapse,
      .collapsing{
        background-color:#edeff5;
      }


    }
    &.section-das-nctucc-dresden,
    &.section-about-nctucc-dresden{
      --bs-primary-rgb:  var(--nct-color-gray-rgb);
      --bs-link-color-rgb: var(--nct-color-gray-rgb);
      --bs-heading-color: var(--nct-color-gray);


      --nct-btn-color: var(--bs-body-color);
      --nct-btn-bg:#f5f5f5;
      --nct-btn-border-color:#f5f5f5;

      --nct-btn-hover-color:var(--bs-body-color);
      --nct-btn-hover-bg: #f1f5f5;
      --nct-btn-hover-border-color: #f1f5f5;

      --nct-btn-active-bg: #f1f5f5;
      --nct-btn-active-border-color: #f1f5f5;

      --nct-btn-disabled-bg: #f5f5f5;
      --nct-btn-disabled-border-color: #f5f5f5;

      // override alert colors
      .alert-dark {
        --bs-alert-bg: rgb(var(--nct-color-gray-rgb));
      }

      // override accordion
      .accordion {
        --bs-accordion-bg: #f5f5f5;
        --bs-accordion-border-color: white;
        --bs-accordion-active-bg: #f5f5f5;
        --bs-accordion-btn-focus-border-color:#f5f5f5;
        --bs-accordion-btn-focus-box-shadow: 0 0 0 0.25rem rgba(var(--nct-color-gray-rgb), 0.25);
      }

      //override collapse
      .collapse,
      .collapsing{
        background-color:#f5f5f5;
      }
    }
  }
}

.btn-primary {
  --bs-btn-color: var(--nct-btn-color);
  --bs-btn-bg: var(--nct-btn-bg);
  --bs-btn-border-color: var(--nct-btn-border-color);
  --bs-btn-hover-color: var(--nct-btn-hover-color);
  --bs-btn-hover-bg: var(--nct-btn-hover-bg);
  --bs-btn-hover-border-color: var(--nct-btn-hover-border-color);
  --bs-btn-focus-shadow-rgb:  var(--nct-btn-focus-shadow-rgb);
  --bs-btn-active-color: var(--nct-btn-active-color);
  --bs-btn-active-bg:  var(--nct-btn-active-bg);
  --bs-btn-active-border-color: var(--nct-btn-active-border-color);
  --bs-btn-active-shadow:  var(--nct-btn-active-shadow);
  --bs-btn-disabled-color: var(--nct-btn-disabled-color);
  --bs-btn-disabled-bg:  var(--nct-btn-disabled-bg);
  --bs-btn-disabled-border-color:  var(--nct-btn-disabled-border-color);
  --bs-btn-border-radius: var(--nct-btn-border-radius);
}