.template-trials-listing-collection-view{

    .planned{
        &::before{
            color:#26547d;
        }
    }
    .endedfollowuprunning{
        &::before{
            color:#ef436b;
        }
    }
    .invitationonly{
        &::before{
            color:#ffce5c;
        }
    }
    .running{
        &::before{
            color:#05c793;
        }
    }
    .stoppedtemporarily{
        &::before{
            color:#a59494;
        }
    }


    .recruitment-states-box{
        list-style-type: none;
        padding-left:0;
        margin-left:0;

        li{
            display:inline-block;
            padding-left:0;
            margin-right:2rem;

            &.endedfollowupended,
            &.stoppedpermanently,
            &.revoked{
                display:none;
            }

            &::before{
                content:"\23FA";
                display:inline-block;
                margin-right:0.5rem;
            }


        }
    }

    .trial-subchapter{

        margin-bottom:2rem;

        h1,h2,h3,h4,h5{

            color:var(--bs-body-color);

            a{
                color:var(--bs-body-color);
                text-decoration:none;
            }
        }
        h3{
            font-size:1.2rem;
            font-weight:400;
            margin-bottom:1rem;
        }
        .card-header{

            font-weight:400;
            font-size:1rem;
            border-bottom:0;

            a {
                display:flex;
                justify-content : space-between;

                &::before{
                    content:"\23FA";
                    display:inline-block;
                    margin-right:1rem;
                }

                &::after{
                    flex-shrink: 0;
                    content: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='16' height='16' fill='rgb(148,148,148)' viewBox='0 0 16 16'%3E%3Cpath d='M0 2a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v12a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2zm5.5 10a.5.5 0 0 0 .832.374l4.5-4a.5.5 0 0 0 0-.748l-4.5-4A.5.5 0 0 0 5.5 4z'/%3E%3C/svg%3E");
                }

                &[aria-expanded="true"]{
                    &::after{
                        transform: rotate(90deg);
                    }
                }

                >span:nth-child(1){
                    flex-grow:2;
                }

                >span:nth-child(2){
                    margin-right: 3rem
                }

            }
        }
    }

    // the portlets


    .portletWrapper{
        margin-bottom:1rem;
    }

    .portlet{
        &.collectionFilter{
            .portletHeader{
                background-color: rgb(var(--bs-primary-rgb));
                color: #fff;
                padding-right: calc(var(--bs-gutter-x)* .5);
                padding-left: calc(var(--bs-gutter-x)* .5);
                padding-top: calc(var(--bs-gutter-x)* .5);
                padding-bottom: calc(var(--bs-gutter-x)* .5);
            }
            .filterItem{

                &.radio{
                    background-color: var(--nct-navigation-portlet-bg-color);
                    border-bottom: 2px solid #fff;
                    padding-right: calc(var(--bs-gutter-x)* .5);
                    padding-left: calc(var(--bs-gutter-x)* 1.5);
                    padding-top: calc(var(--bs-gutter-x)* .5);
                    padding-bottom: calc(var(--bs-gutter-x)* .5);
                    min-height:auto;
                    margin-bottom: 0;
                    &:hover,
                    &.selected{
                        //background-color: rgb(var(--bs-primary-rgb));
                        background-color: #495057;
                        color: #fff;
                        transition: background-color 300ms ease-in-out, color 300ms ease-in-out;
                    }
                    label{
                        display: block;
                        cursor:pointer;
                    }
                }
            }
        }
    }
}
